@use "_mixins";
@use "_extends";
@use "_colors";
.store-summary{display:flex; gap:30px; justify-content:space-between;
    .tabs { cursor: pointer; gap: 20px; display: flex; 
        span { @include mixins.font(1.8, 2.2, 1.2, 700, 0.06rem,  var(--color-off-black)); font-family: "Magazine Grotesque";}
    }
    .store-summary__left{flex:0 1 614px;
        p{@include mixins.font(1.8, 2.2, 1.2, 700, 0, var(--color-off-black), "Magazine Grotesque"); margin-bottom: 10px;
          &:before{content: open-quote;}
          &:after{content: close-quote;}
        }
        label{@include mixins.font(1.4, 1.6, 1.1, 700, 0,  var(--color-off-black));}
    }
    .store-summary__right{flex: 1; padding-top: 8px; display:flex;

        .store-summary__contacts{display:grid; align-items: start; align-content: start; gap: 20px; flex:1.055; border-right: 1px solid #CCCCCC; padding-right: 22px;
            .store-summary__contact{padding-right:20px;
                label{@include mixins.font(1.8, 2.2, 1.2, 700, 0.06rem, var(--color-off-black)); font-family: "Magazine Grotesque"; display:flex; align-items:center; gap:10px;
                    img{}
                }
                p{@include mixins.font(1.4, 1.6, 1.4, 400, 0,  var(--color-off-black)); margin: 19px 0 0;
                  &.address{@include mixins.font(1.4, 1.6, 1.4, 400, 0,  var(--color-off-black));}
                }
                a{@include mixins.font(1.8, 2.4, 1.2, 400, 0,  var(--color-off-black)); margin-top: 15px; display:flex;
                  &.cta{color: var(--color-off-black); font-size:clamp(1.4rem, 1.6vw, 1.6rem);; margin-top: 9px; letter-spacing:0.03rem; gap:10px; text-decoration: underline; font-weight: 700;
                        img{align-self:center;}
                  }
                }
                &:first-child{
                    a{@include mixins.font(2.4, 2.8, 1.2, 700, 0,  var(--color-off-black)); font-family: "Magazine Grotesque"; text-decoration: underline;}
                }
                &:not(&:last-of-type){border-bottom:1px solid #CCCCCC; padding-bottom: 24px;}
            }     
            @include mixins.mq(540){padding-right: 0;}     
        }
        .store-summary__opening-hours{flex:1;
            .hours{margin-left: auto; padding-left: 22px;
                   &>label{@include mixins.font(1.4, 1.4, 2rem, 700, 0.06rem,  var(--color-off-black)); text-transform: uppercase; margin-bottom:19px; display:block;}
                .tab-content{padding-top: 26px;}
                .tabs{padding-left: 0;
                    @include mixins.mq(800){padding-top: 0 !important;}
                    &>span{font-size: clamp(2.4rem, 2.8vw, 2.8rem);
                    &.active{text-decoration: underline;}
                    }
                }
                @include mixins.mq(540){padding-left: 0;}
            }
            .store-summary__opening-hour{display:flex; align-items:center; border-bottom: 1px solid #CCCCCC; padding-bottom:15px; margin-bottom:16px;
                label{flex:1; @include mixins.font(1.4, 1.6, 1, 400, 0,  var(--color-off-black));
                    &:last-child{text-align: right;}
                }
                &.non-standard-hours > label {
                    color: colors.$purple;
                    font-weight: bolder;
                }
                .tooltip-holder { position: relative; cursor: help;
                    &:hover{
                        .opening-times-tooltip{opacity:1; transform: translate(-50%, 0); pointer-events: all;
                            @include mixins.mq(860){ transform: translate(-90%, 0); }
                        }
                    }
                    .opening-times-tooltip{position: absolute; width:165px; font-size:1.4em; background-color: white; box-shadow: 0px 0px 11px rgb(0 0 0 / 9%); border-radius: 8px; padding: 20px; bottom: 30px; left: 50%; opacity: 0; transform: translate(-50%, 5px); pointer-events: none; transition: opacity 0.3s 0s, transform 0.3s 0s ease-out;
                        p{margin: 0;}
                        &:after{content:""; position: absolute; left:50%; bottom:-7px; width:8px; height: 8px; background-color: white; transform: rotate(45deg) translateX(-50%);
                            @include mixins.mq(860){ left:90%; }
                        }
                    }   
                }
            }
        }
    }

     @include mixins.mq(1420){gap:60px;
       .store-summary__left{flex:0 1 500px;}
       .store-summary__right{flex:0 1 580px;}
    }

     @include mixins.mq(1080){flex-direction:column;
        .store-summary__left{max-width:560px; margin: 0 auto; flex:none;}
         .store-summary__right{flex:none;               
            .store-summary__opening-hours{
                .hours{width: auto;}
            }     
            .store-summary__contacts{flex: 1.1;}
         }
    }

     @include mixins.mq(620){flex-direction:column;}

     @include mixins.mq(540){flex-direction:column; gap:43px;
 
         .store-summary__right{flex-direction:column;              
            .store-summary__opening-hours{margin-top:42px;
                .hours{margin-left:0;}
            }     
            .store-summary__contacts{border:none; gap:20px;}
         }
    }
}